<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-card padding>
          <v-container>
            <h1>Perfil</h1>
            <v-form lazy-validation>
              <v-text-field
                v-model="perfil.nome"
                label="Nome"
                name="nome"
                type="text"
                :rules="dtules"
              />

              <v-autocomplete
                v-model="selectedroles"
                label="Módulos"
                :items="roles"
                item-text="nome"
                :multiple="true"
                item-value="roleId"
              />

              <v-btn
                color="primary"
                @click="submit"
              >
                Salvar
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    components: {
    //
    },
    data: () => ({
      roles: [],
      selectedroles: [],
      perfil: {},
      dtules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
      this.$http.get('/perfis/roles')
        .then(resp => {
          this.roles = resp.data
        })
    },
    methods: {
      load () {
        this.$http.get(`/perfis/${this.$route.params.id}`)
          .then(resp => {
            this.perfil = resp.data
            this.selectedroles = resp.data.roles.map(r => r.roleId)
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        this.perfil.roles = this.selectedroles.map(r => { return { roleId: r } })
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/perfis', this.perfil)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/perfil')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/perfis', this.perfil)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/perfil')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style>

</style>
